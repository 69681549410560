@import "src/assets/colors";

.dym {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-left: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-style: italic;
  border-left-width: 2px;
  border-left-style: solid;
}
