@import "src/assets/colors";

.app-call-txn-logs-wrapper {
  .app-call-txn-logs-container {
    margin-top: 20px;
    border-radius: 10px;
    .props {
      margin-top: 0px !important;
    }
    .key {
      margin-bottom: 20px;
    }
    li {
      margin-left: 35px;
      padding-left: 2px;
      list-style-type: decimal-leading-zero;
      margin-bottom: 5px;
    }
    li:last-child {
      margin-bottom: 0px;
    }
    li::marker {
      color: $color-grey;
      font-family: courier;
    }
    .value {
      display: flex;
      flex-direction: column;
      gap: 4px;
      &.start {
        align-items: flex-start !important;
      }
    }
  }
}

.arc28 {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  gap: 0.5rem;
  .title {
    margin-right: 0.25rem;
    font-size: 12px;
    cursor: pointer;
  }
  .nc-flex {
    display: flex;
    align-items: center;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}
