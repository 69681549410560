.nowrap {
  white-space: nowrap;
}

.dimparent {
  .dim > * { opacity: 0.6; }
}

.dimparent:hover {
  .dim > * { opacity: 1 }
}
