@import "src/assets/colors";

.app-call-txn-foreign-assets-wrapper {
  .app-call-txn-foreign-assets-container {
    margin-top: 20px;
    .key {
      margin-bottom: 15px;
    }
    .item {
      margin: 10px 0px;
    }
    ol {
      li {
        display: list-item;
        list-style-type: decimal-leading-zero;
        list-style-position: inside;
        padding-left: 2px;
        margin-bottom: 5px;
      }
      li:last-child {
        margin-bottom: 0px;
      }
      li::marker {
        color: $color-grey;
        font-family: courier;
      }
    }
  }
}
