@import "src/assets/colors";

.application-wrapper {
  .application-container {
    margin-top: 20px;
    .application-header {
      font-size: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .application-body {
      .code-tab-panel {
        padding: 0;
        padding-top: 24px;
        &.padded {
          padding-left: 14px;
          padding-right: 14px;
        }
        .hashtitle {
          margin-top: 24px;
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .hashtitle:first-of-type {
          margin-top: 0;
        }
        .hash {
          display: flex;
          max-width: 100%;
          margin-top: 10px;
          word-break: break-all;
          align-items: start;
          color: $color-grey;
        }
      }
      margin-top: 30px;

      .id {
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: normal;
        display: flex;
        align-items: center;
      }

      .props {
        margin-top: 25px;
        border-radius: 10px;
        padding: 20px;
        padding-top: 10px;
        .property {
          margin-top: 10px;
          word-wrap: anywhere;
          &.center {
          }
          .key {
          }
          .value {
            margin-top: 10px;
            color: $color-grey;
            &.small {
              font-size: 14px;
            }
          }
        }
      }

      .application-tabs {
        margin-top: 20px;
      }
    }
  }
}
