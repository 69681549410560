@import "~reset-css/sass/reset";
@import "./assets/colors";

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-Regular.ttf') format('ttf'),
  url('assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsLight';
  src: url('assets/fonts/Poppins/Poppins-Light.ttf') format('ttf'),
  url('assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsThin';
  src: url('assets/fonts/Poppins/Poppins-Thin.ttf') format('ttf'),
  url('assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsBold';
  src: url('assets/fonts/Poppins/Poppins-Bold.ttf') format('ttf'),
  url('assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

body {
  font-family: "Poppins";
}
* {
  font-family: "Poppins" !important;
}

.modal-close-button {
  &.abs {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
  }
  &:hover {
    cursor: pointer;
  }
}

.HFlex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.no-select {
  user-select: none;
}

.hover-cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

::selection {
  color: $color-background;
  background: #967bb6;
}

.no-underline {
  text-decoration: none !important;
}

.small {
  font-size: 14px;
}

.primary {
  color: $color-primary;
}
