@import "src/assets/colors";

.account-wrapper {
  .account-container {
    margin-top: 20px;
    .account-header {
      font-size: 20px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: calc(100vw - 20px);
    }
    .account-body {
      max-width: calc(100vw - 20px);
      margin-top: 30px;
      .address {
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: normal;
        .id {
          display: flex;
          align-items: center;
          .long-id {
            max-width: calc(100vw - 20px);
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
      .property {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        align-items: center;
        .key {
        }
        .value {
          display: flex;
          align-items: center;
          color: $color-grey;
          .algo-icon {
            margin-left: 5px;
          }
          &.padded {
            padding-right: 8px;
          }
        }
      }
      .account-tabs {
        margin-top: 50px;
        max-width: calc(100vw - 20px);
      }
    }
  }
}
