@import "src/assets/colors";

.transaction-wrapper {
  .transaction-container {
    margin-top: 20px;
    .transaction-header {
      font-size: 20px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .transaction-body {
      margin-top: 30px;
      .index {
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: normal;
        .id {
          display: flex;
          align-items: center;
        }
        .long-id {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: calc(100vw - 100px);
        }
      }

      .props {
        margin-top: 25px;
        border-radius: 10px;
        padding: 20px;
        padding-top: 10px;
        .property {
          margin-top: 10px;
          .key {
          }
          .value {
            margin-top: 10px;
            word-break: break-all;
            display: flex;
            align-items: center;
            &.small {
              font-size: 14px;
            }
          }
        }
      }

      .transaction-tabs {
        margin-top: 25px;
      }
    }
  }
}
