@import "src/assets/colors";

.block-wrapper {
  .block-container {
    margin-top: 20px;
    .block-header {
      font-size: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $color-primary;
      .title {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }
      .navigate {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        color: $color-grey;
      }
    }
    .block-body {
      margin-top: 30px;
      .address {
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: normal;
      }
      .props {
        margin-top: 25px;
        border-radius: 10px;
        padding: 0;
        .property {
          margin-top: 10px;
          .key {
            .flex {
              display: flex;
              gap: 0.2rem;
              align-items: center;
              width: fit-content;
            }
          }
          .value {
            display: flex;
            align-items: center;
            margin-top: 10px;
            color: $color-grey;
            .long-id {
              display: inline-block;
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: calc(100vw - 20px);
            }
            &.small {
              font-size: 14px;
            }
            .mini-alert {
              padding: 5px 10px;
              border-radius: 4px;
              display: inline-block;
              margin: 5px 5px 0 0;
              font-weight: bold;
              text-align: center;
              font-size: 14px;
              .MuiAlert-message {
                padding: 0;
              }
            }
          }
        }
      }
      .block-tabs {
        margin-top: 50px;
      }
    }
  }
}
