@import "src/assets/colors";

.json-viewer-content {
  font-size: 13px;
  .json-viewer-content-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-primary;
    padding-bottom: 15px;
    margin-bottom: 15px;
    width: 100%;
  }
}

.json-viewer-wrapper {
  .json-viewer-container {

  }
}
