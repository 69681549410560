@import "src/assets/colors";

.home-wrapper {
  .home-container {
    position: relative;
    margin-top: 250px;
    @media only screen and (max-width: 900px) {
      margin-top: 225px;
    }
    .grey {
      color: $color-grey;
    }
    .home-body {
      position: relative;
      margin-left: 2px;
      margin-top: 100px;
      .bg-logo {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -45%);
        opacity: 0.11;
        z-index: 0;
        max-width: 80vw;  
      }
      .tag-line {
        font-size: 24px;
        margin-left: 10px;
      }
      .search-section {
        margin-top: 25px;
        margin-bottom: 200px;
        @media only screen and (max-width: 900px) {
          margin-bottom: 200px;
          margin-left: 18px;
        }
      }
      .live-section {
        margin-top: 60px;
        display: flex;
        //background: $color-grey-very-light;
        //border-radius: 10px;
        //padding: 30px;
      }
    }
  }
}
