@import "src/assets/colors";

.search-wrapper {
  .search-container {
    @media only screen and (max-width: 999px) {
      margin-left: -20px;
      margin-top: 8px;
    }
  }
}
