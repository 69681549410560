.footer:hover {
  opacity: 1;
}

.footer {
  display: flex;
  transition: opacity 250ms;
  justify-content: space-between;
  align-items: center;
  font-size: 85%;
  margin-top: 25px;
  opacity: 0.6;
  .left {
    display: flex;
    align-items: center;
    a:first-child {
      margin-left: 10px;
    }
    a {
      margin-left: 6px;
      display: inline-flex;
      align-items: center;
    }
    .sociallogo {
      margin-left: 2px;
      height: 14px;
    }
  }

  .current {
    text-decoration: none !important;
    color: white;
  }
}
@media only screen and (max-width: 900px) {
  .footer {
    padding: 10px 10px 10px 10px;
  }
}
@media only screen and (min-width: 901px) {
  .footer {
    padding: 10px 100px 10px 100px;
  }
}

