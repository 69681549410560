@import "src/assets/colors";

.app-call-txn-arguments-wrapper {
  .app-call-txn-arguments-container {
    margin-top: 20px;
    border-radius: 10px;
    .props {
      margin-top: 0px !important;
    }
    .key {
      margin-bottom: 15px;
    }
    .item {
      margin: 7px 0px;
    }
    li {
      margin-left: 35px;
      padding-left: 2px;
      list-style-type: decimal-leading-zero;
      margin-bottom: 5px;
    }
    li:last-child {
      margin-bottom: 0px;
    }
    li::marker {
      color: $color-grey;
      font-family: courier;
    }

    .plain-args {
      font-size: 14px;
      .arg {
        padding: 2px 0px;
        .arg-prop {
          font-size: 14px;
        }
      }
    }
    .abi-decoded-args {
      .arguments {
        margin-top: 20px;
        .arguments-header {
          padding: 15px;
          font-weight: bold;
        }
        .arg {
          padding: 15px;
          .arg-prop {
            font-size: 14px;
          }
          &:nth-child(odd) {
          }
        }
      }
    }
  }
}
