@import "src/assets/colors";

.assets-list-wrapper {
  .assets-list-container {
    .assets-list-header {
      margin-top: 40px;
      font-size: 20px;
      font-weight: bold;
    }
    .assets-list-body {
      margin-top: 50px;
      .balance-cell {
        display: flex;
        gap: 0.3rem;
        align-items: center;
        color: #46daff;
      }
    }
  }
}