@import "src/assets/colors";

.accounts-wrapper {
  .accounts-container {
    .accounts-header {
      margin-top: 40px;
      font-size: 20px;
      font-weight: bold;
    }
    .accounts-body {
      margin-top: 50px;
      .copy-content {
        font-size: 16px;
        margin-right: 10px;
        vertical-align: text-bottom;
      }
    }
  }
}