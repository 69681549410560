@import 'src/assets/colors';

.loading-box {
  width: 300px;
  height: 270px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
  margin: 0 auto;
  color: $color-primary;
  opacity: 1;
  position: absolute;
  top: 200px;
  left: 42%;
  z-index: 2001;
  text-align: center;
  padding-top: 20px;
  border-radius: 15px;
  .progress-bar {
    margin-top: 75px;
  }
  img {
    margin-top: 10px;
    width: 50%;
  }
  .message {
    margin-top: 25px;
    padding: 0px 25px;
  }
}

.loader-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  background: $color-dark;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 2000;
}
