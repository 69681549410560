@import "src/assets/colors";

.applications-wrapper {
  .applications-container {
    .applications-header {
      margin-top: 40px;
      font-size: 20px;
      font-weight: bold;
    }
    .applications-body {
      margin-top: 50px;
    }
  }
}