@import "src/assets/colors";

.transaction-multi-sig-wrapper {
  .transaction-multi-sig-container {
    margin-top: 30px;
    .transaction-multi-sig-header {
      font-size: 20px;
      font-weight: bold;
    }
    .transaction-multi-sig-body {
      .threshold-version-container {
        display: flex;
        width: 100%;
        padding-top: 10px;
        justify-content: space-around;
        flex-direction: row-reverse;
        .value {
          color: $color-primary;
          font-size:110%;
          margin-left: 10px;
          margin-right: 10px;
        }
        margin-bottom: 15px;
      }
      .value.column {
        flex-direction: column;
        align-items: flex-start !important;
      }
      .sub-sig {
        margin: 12px 0px;
        font-size: 14px;
        display: flex;
        align-items: center;
        .icon {
          margin-right: 5px;
          cursor: help;
          &.off {
            opacity: 0.5;
            margin-left: 4px;
            margin-right: 6px;
          }
        }
      }
    }
  }
}
