@import "src/assets/colors";

.custom-error-wrapper {
  .custom-error-container {
    .info {
      margin-top: 150px;
      margin-bottom: 30px;
    }
    .actions {

    }
  }
}