@import "src/assets/colors";

.live-blocks-wrapper {
  .live-blocks-container {
    text-align: left;
    .live-blocks-header {
      font-size: 20px;
    }
    .live-blocks-body {
      margin-top: 20px;
      .block {
        padding: 15px;
        margin: 12px 0;
        display: flex;
        justify-content: space-between;
        transition: height 2s;
        border-left: 1px solid $color-primary;
        border-left-width: 6px;
        border-radius: 4px;
        overflow: hidden;
        .txn-count {
          font-size: 15px;
          font-weight: bold;
        }
        &.item-enter {
          opacity: 0;
          transform: scale(0.9);
        }
        &.item-enter-active {
          opacity: 1;
          transform: translateX(0);
          transition: opacity 1000ms, transform 1000ms;
        }
        .round {
          width: 100%;
          .round-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

          }
          .text-right {
            text-align: right;
            flex-grow: 1;
          }
          .faded {
            color: $color-grey;
          }
          .sub-text {
            margin-top: 15px;
            font-size: 13px;
          }
          a {
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
    }
  }
}
