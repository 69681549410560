@import "src/assets/colors";

.asset-config-transaction-wrapper {
  .asset-config-transaction-container {
    margin-top: 30px;
    .asset-config-transaction-header {
      font-size: 20px;
      font-weight: bold;
    }
    .asset-config-transaction-body {
      .props {
        margin-top: 20px;
        border-radius: 10px;
        padding: 20px;
        padding-top: 10px;
        .property {
          margin-top: 10px;
          .key {
          }
          .value {
            margin-top: 10px;
            word-break: break-all;
            &.small {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
