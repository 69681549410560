@import "src/assets/colors";

.block-transactions-wrapper {
  .block-transactions-container {
    .block-transactions-body {
      .transactions-list-body {
        margin-top: 20px;
      }
      .copy-content {
        font-size: 16px;
        margin-right: 10px;
        vertical-align: text-bottom;
      }
    }
  }
}