@import "src/assets/colors";

.header-wrapper {
  .header-container {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 999px) {
      margin-left: 20px;
      flex-direction: column;
      button {
        min-width: 50px;
      }
    }

    .settings-icon {
      margin-top: 12px;
      margin-left: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
