.app-root {
  .app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    .app-right {
      width: 100%;
      max-width: 1280px;
      .content-wrapper {
        overflow: auto;
        min-height: 100svh;
        display: flex;
        flex-direction: column;
        .content-container {
          flex-grow: 1;
        }
        @media only screen and (max-width: 900px) {
          .content-container {
            margin: 10px 10px 0px 10px;
          }
        }
        @media only screen and (min-width: 901px) {
          .content-container {
            margin: 20px 100px 0px 100px;
          }
        }
      }
    }
  }

}
