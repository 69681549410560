@import "src/assets/colors";

.account-assets-wrapper {
  .account-assets-container {
    .account-assets-body {
      .assets-list-body {
        margin-top: 20px;
      }
      .copy-content {
        font-size: 16px;
        margin-right: 10px;
        vertical-align: text-bottom;
      }
    }
  }
}