@import "src/assets/colors";

.inner-transaction-wrapper {
  .inner-transaction-container {
    margin-top: 20px;
    .inner-transaction-header {
      font-size: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .inner-transaction-body {
      margin-top: 30px;
      .index {
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: normal;
      }

      .props {
        margin-top: 25px;
        border-radius: 10px;
        padding: 20px;
        padding-top: 10px;
        .property {
          margin-top: 10px;
          .key {
          }
          .value {
            margin-top: 10px;
            word-break: break-all;
            &.small {
              font-size: 14px;
            }
          }
        }
      }

      .inner-transaction-tabs {
        margin-top: 25px;
      }
    }
  }
}
