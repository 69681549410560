@import "src/assets/colors";

.account-controller-to-wrapper {
  .account-controller-to-container {
    .account-controller-to-body {
      .copy-content {
        font-size: 16px;
        margin-right: 10px;
        vertical-align: text-bottom;
      }
    }
  }
}
