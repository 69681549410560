@import "src/assets/colors";

.transaction-additional-details-wrapper {
  .transaction-additional-details-container {
    .value {
      display: flex;
      align-items: center;
      .rnddelta {
        font-size: 80%;
        margin-left: 3px;
      }
    }
  }
}
