@import "src/assets/colors";

.assets-wrapper {
  .assets-container {
    .assets-header {
      margin-top: 40px;
      font-size: 20px;
      font-weight: bold;
    }
    .assets-body {
      margin-top: 50px;
    }
  }
}