@import "src/assets/colors";

.local-state-container {
  .local-state-header {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .local-state-body {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
    .line {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.account-opted-applications-wrapper {
  .account-opted-applications-container {
    .account-opted-applications-body {
      .applications-list-body {
        margin-top: 20px;
      }
      .copy-content {
        font-size: 16px;
        margin-right: 10px;
        vertical-align: text-bottom;
      }
    }
  }
}