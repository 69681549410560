@import "src/assets/colors";

.group-transactions-wrapper {
  .group-transactions-container {
    .group-transactions-header {
      margin-top: 40px;
      font-size: 20px;
      font-weight: bold;
    }
    .group-transactions-body {
      margin-top: 50px;
      .copy-content {
        font-size: 16px;
        margin-right: 10px;
        vertical-align: text-bottom;
      }
    }
  }
}