@import "src/assets/colors";

.app-call-txn-global-state-delta-wrapper {
  .app-call-txn-global-state-delta-container {
    border-radius: 10px;
    margin-top: 30px;
    .props {
      margin-top: 0px !important;
    }
  }
}
