@import "src/assets/colors";

.loading-tile-wrapper {
  .loading-tile-container {

  margin-top: 50px;
    .wrapper {
      .wrapper-cell {
        .text {
          display: flex;
          flex-direction: column;
          align-items: center;
          .text-line {
            height: 20px;
            margin: 10px 0;
            @extend .animated-background;
            border-radius: 20px;
            &:nth-child(1) {
              opacity: 0.05;
              animation-delay: 200ms;
              width: 100%;
            }
            &:nth-child(2) {
              opacity: 0.175;
              animation-delay: 100ms;
              width: 70%;
            }
            &:nth-child(3) {
              opacity: 0.5;
              width: 33%;
              animation-delay: 0ms;
            }
            &:nth-child(4) {
              opacity: 0.175;
              animation-delay: 100ms;
              width: 90%;
            }
            &:nth-child(5) {
              opacity: 0.05;
              animation-delay: 200ms;
              width: 60%;
            }
          }
        }
      }
    }
    @keyframes placeHolderShimmer{
      50%{
        width: 20px;
      }
    }
    .animated-background {
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: ease-in-out;
      background: $color-primary;
    }
  }
}
