@import "src/assets/colors";

.group-wrapper {
  .group-container {
    margin-top: 20px;
    .group-header {
      font-size: 20px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .group-body {
      margin-top: 30px;
      .id {
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: normal;
      }
      .props {
        max-width: 500px;
        margin-top: 30px;
        padding-right: 8px;
        .property {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          .key {
            margin-right: 4px;
            &.nowrap {
              white-space: pre;
            }
          }
          .value {
            color: $color-grey-dark;
            .algo-icon {
              margin-left: 5px;
            }
            &.flexwrap {
              display: flex;
              gap: 2px;
              flex-wrap: wrap;
            }
          }
        }
      }
      .group-tabs {
        margin-top: 50px;
      }
    }
  }
}
