@import "src/assets/colors";
.dialogflexgroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media only screen and (max-width: 970px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  gap: 0.25rem;
  margin-bottom: 1rem;
}
.account-validator-container .row,
.dialogflexgroup .row {
  &.grow {
    flex-grow: 1;
  }
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  min-width: calc(min(90%, 375px));
  color: $color-grey;
  .row-value {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: white;
  }
  &.buttons {
    gap: 0.5rem;
    align-self: center;
  }
  @media only screen and (max-width: 970px) {
    align-self: center;
    &.buttons {
      flex-direction: column;
      align-self: flex-start;
    }
  }
}
.account-validator-wrapper {
  .account-validator-container {
    margin-top: 1.5rem;
    padding-left: 5px;
    padding-right: 5px;

    .account-validator-footer {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
      .row {
        min-width: auto;
      }
      .loading {
        width: 100%;
      }
    }
    .account-validator-body {
      .row {
        max-width: 450px;
      }
      display: flex;
      flex-direction: row-reverse;
      @media only screen and (max-width: 970px) {
        flex-direction: column;
      }
      // flex-direction: column;
      // align-items: stretch;
      // gap: 1.5rem;
      .timeline-selector {
        align-self: flex-start;
        color: $color-grey-mid-dark;
        font-size: 14px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .label {
          margin-top: 0.25rem;
          margin-bottom: 0.25rem;
        }
        @media only screen and (max-width: 970px) {
          width: 100%;
          justify-content: flex-end;
          margin-bottom: 0.5rem;
          .label {
            align-self: flex-start;
          }
          .buttongroup {
            align-self: flex-end;
          }
        }
      }
      .group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media only screen and (max-width: 970px) {
          flex-direction: column;
          justify-content: flex-start;
        }
        gap: 0.4rem;
        margin-bottom: 1rem;
      }
      .body {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 1rem;
      }
    }
  }
}
