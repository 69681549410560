@import "src/assets/colors";

.accounts-list-wrapper {
  .accounts-list-container {
    .accounts-list-header {
      margin-top: 40px;
      font-size: 20px;
      font-weight: bold;
    }
    .accounts-list-body {
      margin-top: 20px;
      .copy-content {
        font-size: 16px;
        margin-right: 10px;
        vertical-align: text-bottom;
      }
    }
  }
}
