@import "src/assets/colors";

.upgrade-body {
  position: absolute;
  top: -180px;
  right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 95vw;
  overflow-x: hidden;
  .left {
    flex-wrap: nowrap;
    white-space: nowrap;
    font-size: 24px;
    padding-right: 14px;
    @media only screen and (max-width: 900px) {
      font-size: 20px;
      padding-right: 10px;
    }
  }
  .right {
    border-left: 2px $color-primary solid;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    @media only screen and (max-width: 900px) {
      padding-left: 10px;
    }
    line-height: 1.8rem;
    min-width: 350px;
    max-width: 95vw;
  }
  .grey {
    color: $color-grey;
  }
}
